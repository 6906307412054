import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
import disableDevTools from "disable-devtool";
loadFonts();
document.addEventListener("contextmenu", (e) => e.preventDefault());

document.addEventListener("keydown", (e) => {
  if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I") || (e.ctrlKey && e.shiftKey && e.key === "J") || (e.ctrlKey && e.key === "U")) {
    e.preventDefault();
  }
});
disableDevTools();
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "devbms.commdealdata.com" || window.location.hostname === "localhost"
        ? "us-east-1_mUWvuwT3H"
        : window.location.hostname === "testbms.commdealdata.com" 
        ? "us-east-1_JJ6qxHMKb"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "us-east-1_wqNM6Fkrn"
        : "us-east-1_porUfhUp6",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "devbms.commdealdata.com" || window.location.hostname === "localhost"
        ? "7oaq7f2ashs9ae43rmgkkavqn5"
        : window.location.hostname === "testbms.commdealdata.com" 
        ? "6qoonik7k4tutvi5rm46mqk9po"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "2lek4bre9j4n02vdqi9dvr97gh"
        : "6v8064i7novl07ub4h44j0pdg3",
  },

  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "devbms.commdealdata.com" || window.location.hostname === "localhost"
        ? "https://trnffuhbgjgjvj37bqkj7t5mc4.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "testbms.commdealdata.com" 
        ? "https://fxipxjy5dbfxxioy4o2oufah3y.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "https://pufkzcx67vbl3adapj2g7zz26m.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://7og6seqawrez3fsc7r7sa7pory.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
createApp(App).use(router).use(store).use(vuetify).mount("#app");
